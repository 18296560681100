import React, { useState } from "react";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";

import { Modal, ModalHeader, ModalBody, ModalCloseBtn } from "Molecules";
import { modalNames } from "Constants";
import { IModalContext, useLanguage, useQopla } from "Providers";
import { ComboDiscount, ComboDiscountQueries, ComboDiscountWithSelections } from "./utils/comboDiscountFormTypes";
import { useMothershipMutation, useMothershipQuery } from "Hooks";
import { GET_CATEGORIES_WITH_PRODUCTS_BY_COMPANY_ID, GET_COMPANY_SHOPS } from "GraphQLQueries";
import { LoadingMessage, successNotification } from "TempUtils";
import { allowedCompanyShops } from "../discount-admin/utils/DiscountAdminFunctions";
import { getComboDiscountInitialValues } from "./utils/comboDiscountInitialValues";
import { Button, Flex, RHForm } from "Atoms";
import { ComboDiscountForm } from "./components/ComboDiscountForm";
import { ComboDiscountProductForm } from "./components/ComboDiscountProductForm";
import { ComboDiscountLimits } from "./components/ComboDiscountLimits";
import { ComboDiscountProductSummary } from "./components/ComboDiscountProductSummary";
import { formatFinancialNumbers, parseDateForJavaLocalDateTime } from "Utils";
import { UPSERT_COMBO_DISCOUNT } from "GraphQLMutations";
import { useQoplaStore } from "Stores";

export type ModalContentProps = {
    comboDiscount?: ComboDiscount;
    updateQuery: <T>(mapFn: (previousQueryResult: T) => T) => void;
    isACopy: boolean;
};

export const ComboDiscountModal: React.FC<IModalContext<ModalContentProps>> = ({ modalContent, closeModal }) => {
    const [showProductSelection, setShowProductSelection] = useState<boolean>(false);
    const { comboDiscount, updateQuery, isACopy } = modalContent;
    const { authenticatedUser } = useQopla();
    const {
        companyLocale,
        selectedCompany: { id: companyId }
    } = useQoplaStore();
    const { translate } = useLanguage();

    const editDiscount = !!comboDiscount?.id;

    const [createComboDiscountMutation] = useMothershipMutation(UPSERT_COMBO_DISCOUNT);

    const { data: companyShops, loading: isLoadingCompanyShops } =
        useMothershipQuery<ComboDiscountQueries.CompanyShops>(GET_COMPANY_SHOPS, {
            variables: { companyId: companyId },
            fetchPolicy: "network-only"
        });

    const { data: categoriesAndProducts, loading: isLoadingCategoriesAndProducts } =
        useMothershipQuery<ComboDiscountQueries.CategoryAndProducts>(GET_CATEGORIES_WITH_PRODUCTS_BY_COMPANY_ID, {
            variables: { companyId: companyId },
            fetchPolicy: "network-only"
        });

    if (isLoadingCategoriesAndProducts || isLoadingCompanyShops) return <LoadingMessage />;

    /** If authenticated user is allowed access to shops */
    const getAllowedShopList = allowedCompanyShops(authenticatedUser, isACopy, companyShops?.getCompanyShops);
    const shopIdsNotAbleToAccess = getAllowedShopList.filter(value => value.isDisabled).map(opt => opt.value);

    /** Get shop ids used in the discount  **/
    const hasShopIds = comboDiscount?.shopIds && getAllowedShopList;
    const shopsUsedInDiscount =
        hasShopIds && getAllowedShopList.filter(opt => comboDiscount.shopIds.includes(opt.value));

    /** Shop ids for initial values  */
    const discountShopIds = shopsUsedInDiscount?.map(opt => opt.value) ?? [];

    /** Check for shops that have limited access
     * 1. canEditDiscount - if there is an id present it will be editable however if it is a copy then it is treated as a new discount
     * 2. hasDisabledShopsInDiscount - this checks the discounts shop ids against any that are not selectable
     * NOTE: this is telling inputs to disable as an admin could have access to one shop and still use the discount on that shop
     * 3. shouldDisableFormInputs - if discount has disabled ids and it is an edit discount (not new or a copy) it than is passed on to disable
     */
    const canEditDiscount = editDiscount && !isACopy;
    const hasDisabledShopsInDiscount = shopIdsNotAbleToAccess?.some(id => discountShopIds.includes(id)) ?? false;
    const shouldDisableFormInputs = hasDisabledShopsInDiscount && canEditDiscount;

    const reInitialiseDiscount: Partial<ComboDiscount> = {
        ...comboDiscount,
        disabled: editDiscount ? !comboDiscount.disabled : false,
        shopIds: discountShopIds,
        ...((isACopy || !editDiscount) && { name: "", endDate: null })
    };

    let modalHeader: string = editDiscount
        ? `${translate("update")} "${comboDiscount.name}"`
        : translate("createANewDiscount");

    if (isACopy) {
        modalHeader = `${translate("copyDiscount")} - "${comboDiscount?.name}"`;
    }

    const comboCategorySchema = Yup.object().shape({
        menuProductIds: Yup.array().min(1, translate("mustSelectAtLeastOne")).required()
    });

    const formSchema = Yup.object().shape({
        name: Yup.string().required(translate("mustFillIn")),
        shopIds: Yup.array().required(translate("mustSelectAtLeastOne")),
        startDate: Yup.date().test("validateStartDate", translate("mustChooseAStartDate"), (value: moment.Moment) => {
            return moment(value).isValid();
        }),
        limit: Yup.number().test("validateLimit", translate("mustFillIn"), (value: number) => {
            return value > 0;
        }),
        maxPerPurchase: Yup.number().test("validateMaxPerPurchase", translate("mustFillIn"), (value: number) => {
            return value >= 0;
        }),
        staticPrice: Yup.number().required(translate("mustFillIn")),
        comboCategories: Yup.array().of(comboCategorySchema).min(1, translate("mustSelectAtLeastOne")).required()
    });

    const { initialValues } = getComboDiscountInitialValues(companyId, reInitialiseDiscount as ComboDiscount);

    const companyCategoriesAndProducts = categoriesAndProducts?.getCategoriesWithProducts;

    const onSubmit = async (formValues: ComboDiscountWithSelections) => {
        const { selection, limit, maxPerPurchase, ...values } = formValues;
        const comboDiscountToPost: Partial<ComboDiscount> = {
            ...values,
            disabled: !values.disabled,
            startDate: parseDateForJavaLocalDateTime(moment(values.startDate).startOf("day")),
            endDate: values.endDate
                ? parseDateForJavaLocalDateTime(moment(values.endDate).endOf("day"))
                : parseDateForJavaLocalDateTime(moment().add(25, "years"))
        };

        if (isACopy) {
            Reflect.deleteProperty(comboDiscountToPost, "id");
        }

        try {
            const { data } = await createComboDiscountMutation({ variables: { comboDiscount: comboDiscountToPost } });
            if (data && data?.upsertComboDiscount) {
                const { upsertComboDiscount } = data;
                updateQuery((prev: any) => {
                    if (editDiscount && !isACopy) {
                        return {
                            getComboDiscounts: prev.getComboDiscounts?.map((discount: ComboDiscount) => {
                                return discount.id === upsertComboDiscount.id ? upsertComboDiscount : discount;
                            })
                        };
                    }
                    return {
                        getComboDiscounts: prev.getComboDiscounts?.concat([upsertComboDiscount])
                    };
                });
                closeModal(modalNames.DISCOUNTCOMBOMODAL);
                successNotification(
                    `${values.name}`,
                    1400,
                    `${translate("setPrice")} ${`${formatFinancialNumbers(values.staticPrice, companyLocale)}`}`
                );
            }
        } catch (error) {
            console.log("Posting combo discout failed!!", error);
        }
    };

    return (
        <Modal open={true} onClose={() => closeModal(modalNames.DISCOUNTCOMBOMODAL)} size="md" isScrolling>
            <ModalCloseBtn onClick={() => closeModal(modalNames.DISCOUNTCOMBOMODAL)} />
            <ModalHeader>{modalHeader}</ModalHeader>
            <ModalBody>
                <RHForm<ComboDiscountWithSelections>
                    useFormProps={{ defaultValues: initialValues, resolver: yupResolver(formSchema), mode: "onBlur" }}
                >
                    {({ handleSubmit, formState: { isValid, isDirty, isSubmitting } }) => {
                        const saveButtonIsDisabled = !(isValid && isDirty);
                        return (
                            <form onSubmit={handleSubmit(onSubmit)}>
                                {!showProductSelection ? (
                                    <ComboDiscountForm
                                        shopOptions={getAllowedShopList}
                                        disableFormInputs={shouldDisableFormInputs}
                                    />
                                ) : (
                                    <>
                                        <ComboDiscountProductForm
                                            companyCategoriesAndProducts={companyCategoriesAndProducts}
                                        />
                                        <ComboDiscountLimits />
                                        <ComboDiscountProductSummary
                                            companyCategoriesAndProducts={companyCategoriesAndProducts}
                                        />
                                    </>
                                )}

                                <Flex justify="space-between">
                                    <Button type="button" onClick={() => setShowProductSelection(prev => !prev)}>
                                        {!showProductSelection ? translate("products") : translate("back")}
                                    </Button>
                                    <Button
                                        type="submit"
                                        themeColor="green"
                                        disabled={saveButtonIsDisabled}
                                        isLoading={isSubmitting}
                                    >
                                        {translate("save")}
                                    </Button>
                                </Flex>
                            </form>
                        );
                    }}
                </RHForm>
            </ModalBody>
        </Modal>
    );
};
