import React, { useMemo } from "react";
import { MdDelete } from "@react-icons/all-files/md/MdDelete";
import { flatMap } from "lodash";
import { useFormContext, useWatch } from "react-hook-form";

import { useLanguage } from "Providers";
import { CategoryProducts, ComboDiscountWithSelections } from "../utils/comboDiscountFormTypes";
import { Box, Flex, Header, NewGrid as Grid, Text } from "Atoms";
import { IconButton } from "Molecules";
import { roundToTwo } from "NumberUtils";

type Props = {
    companyCategoriesAndProducts?: CategoryProducts[];
};

type Summary = {
    menuProdutId: string;
    categoryName: string;
    productName: string;
    defaultPrice: number;
    defaultTotal: number;
    potentialDiscount: number;
};

export const ComboDiscountProductSummary: React.FC<Props> = ({ companyCategoriesAndProducts }) => {
    const { translate } = useLanguage();
    const { control, setValue } = useFormContext<ComboDiscountWithSelections>();
    const comboCategories = useWatch({ control, name: "comboCategories" });
    const limit = useWatch({ control, name: "limit" });
    const staticPrice = useWatch({ control, name: "staticPrice" });

    const menuProductIds = flatMap(comboCategories?.map(com => com.menuProductIds));
    const hasComboProducts = !!menuProductIds.length;

    const removeMenuProductId = (menuProductId: string) => {
        if (comboCategories?.length) {
            const updateComboCategories = {
                ...comboCategories[0],
                menuProductIds: comboCategories[0].menuProductIds.filter(id => id !== menuProductId)
            };
            setValue("comboCategories", [updateComboCategories], { shouldDirty: true, shouldValidate: true });
        }
    };

    const summaryValues = useMemo(() => {
        if (comboCategories?.length) {
            const selectedSummary = companyCategoriesAndProducts?.reduce(
                (products: Summary[], category: CategoryProducts) => {
                    const productLists = flatMap([
                        ...(category.refProductList ?? []),
                        ...(category.refBundleProductList ?? [])
                    ]);
                    const productSummary: Summary[] = productLists
                        .filter(prod => menuProductIds.includes(prod.id))
                        .map(prod => ({
                            menuProdutId: prod.id,
                            categoryName: category.name,
                            productName: prod.name,
                            defaultPrice: prod.defaultPrice,
                            defaultTotal: prod.defaultPrice * limit,
                            potentialDiscount: roundToTwo(prod.defaultPrice * limit - staticPrice) ?? 0
                        }));
                    return [...products, ...productSummary];
                },
                []
            );
            return selectedSummary;
        }
        return [];
    }, [comboCategories, limit, staticPrice]);

    return (
        <Box bg={hasComboProducts ? "gray.100" : "white"} p={3} mb={4}>
            {hasComboProducts && (
                <>
                    <Header as="h4" size="sm">
                        {translate("products")}
                        <Box as="sup">{translate("productsCanBeCombined")}</Box>
                    </Header>
                    <Box as="sup"> {translate("comboDiscountCalculation")}</Box>
                </>
            )}

            {summaryValues?.map(sum => {
                const potentialDiscountColour = sum.potentialDiscount < 0 ? "red.500" : "orange.500";
                return (
                    <Flex align="center" bg="gray.200" rounded="lg" p={1} mb={2} key={sum.menuProdutId}>
                        <Grid templateColumns="repeat(3,1fr ) 5rem 3rem" gap="1rem" alignItems="center" ml={2}>
                            <Box>{sum.categoryName}</Box>
                            <Box>{sum.productName}</Box>
                            <Text fontWeight="700" m={0} p={0} whiteSpace="nowrap">
                                {sum.defaultPrice} x {limit} = {sum.defaultTotal} ({staticPrice})
                            </Text>
                            <Text color={potentialDiscountColour} m={0} p={0} fontWeight="700">
                                {`( ${sum.potentialDiscount} )`}
                            </Text>
                            <Box justifyItems="flex-end" display="inline-grid">
                                <IconButton
                                    icon={MdDelete}
                                    type="button"
                                    themeColor="red"
                                    onClick={() => removeMenuProductId(sum.menuProdutId)}
                                    m={1}
                                />
                            </Box>
                        </Grid>
                    </Flex>
                );
            })}
        </Box>
    );
};
