import React, { useMemo } from "react";
import { flatMap } from "lodash";
import { useFormContext, useWatch } from "react-hook-form";

import { CategoryProducts, ComboDiscountWithSelections } from "../utils/comboDiscountFormTypes";
import { useLanguage } from "Providers";
import { SelectOption } from "Molecules";
import { Box, Button, Flex, FormErrorMessage, Header, RHSelectInput } from "Atoms";

type Props = {
    companyCategoriesAndProducts?: CategoryProducts[];
};

export const ComboDiscountProductForm: React.FC<Props> = ({ companyCategoriesAndProducts }) => {
    const { translate } = useLanguage();
    const {
        control,
        setValue,
        getValues,
        formState: { errors }
    } = useFormContext<ComboDiscountWithSelections>();

    const selection = useWatch({ control, name: "selection" });
    const comboCategories = useWatch({ control, name: "comboCategories" });

    const comboProductError = errors.comboCategories ? errors.comboCategories[0]?.menuProductIds?.message : "";

    const hasCategoriesAndProducts = !!companyCategoriesAndProducts?.length;
    const allSelectedProductIds = flatMap(comboCategories?.map(value => value.menuProductIds));

    const categoryOptions: SelectOption[] = hasCategoriesAndProducts
        ? companyCategoriesAndProducts.map(cat => ({ label: cat.name, value: cat.id }))
        : [];

    /** Checks to see if the category has refproducts or refbunldeproduct */
    const hasProductLists = (category: CategoryProducts) => {
        return !!category.refProductList?.length || !!category.refBundleProductList?.length;
    };

    /**
     * Gets all select option products in ref product or bundle by the selected category
     */
    const getProductListByCategoryId = (categoryId: string): SelectOption[] => {
        const productList = companyCategoriesAndProducts
            ?.filter(cat => cat.id === categoryId && hasProductLists(cat))
            .map(cat => {
                // add together list without already selected product ids
                return [...(cat.refProductList ?? []), ...(cat.refBundleProductList ?? [])]?.map(prod => ({
                    label: prod.name,
                    value: prod.id,
                    isDisabled: allSelectedProductIds.includes(prod.id)
                }));
            });
        return flatMap(productList);
    };

    /**
     * Set combo categories (List of products for the discount) based on existing data or new selections
     */
    const onSetComboCategories = (productIds: string[]) => {
        const hasComboCategory = !!comboCategories?.length;
        const { limit, maxPerPurchase } = getValues();
        if (!!productIds.length && hasComboCategory) {
            // if discount already has product ids from its combo category then add to it
            const { menuProductIds = [] } = comboCategories[0] || {};
            setValue(
                "comboCategories",
                [
                    {
                        limit: limit,
                        maxPerPurchase: maxPerPurchase,
                        menuProductIds: [...new Set([...menuProductIds, ...productIds])]
                    }
                ],
                { shouldValidate: true }
            );
        } else {
            // if this is a new discount then create new combo category
            setValue(
                "comboCategories",
                [
                    {
                        limit: limit,
                        maxPerPurchase: maxPerPurchase,
                        menuProductIds: productIds
                    }
                ],
                { shouldValidate: true }
            );
        }
        setValue("selection.productIds", []);
    };

    /**
     * Add all products under a selected category
     */
    const addAllCategoryProducts = () => {
        const { categoryId = "" } = selection || {};
        const categoryProductIds = getProductListByCategoryId(categoryId).map(prod => prod.value);
        onSetComboCategories(categoryProductIds);
    };

    /**
     * Add all selected products to the form
     */
    const addSelectedProducts = () => {
        const { productIds = [] } = selection || {};
        onSetComboCategories(productIds);
        setValue("selection.productIds", []);
    };

    /**
     * Gets all product options based on the category id changing and the length of already selected product ids
     * (Note if products are already choosen then they will be disabled)
     */
    const productOptions = useMemo(() => {
        const categoryId = selection?.categoryId;
        if (!categoryId) {
            return [];
        }
        return getProductListByCategoryId(categoryId);
    }, [selection?.categoryId, comboCategories]);

    /** Disable category select & product select (based on selections)
     * Also checks if every product has been selected to disable the add category button
     */
    const disableCategorySelect = !selection?.categoryId;
    const disableProductSelect = !selection?.productIds?.length;
    const hasEveryProductSelected = productOptions.every(prod => prod.isDisabled);

    return (
        <Box bg="gray.100" p={4} mb={4}>
            <Header as="h6" size="sm" display="flex" justifyContent="center" alignItems="center">
                {translate("addProduct")}
            </Header>
            <RHSelectInput
                formLabel={translate("category")}
                name="selection.categoryId"
                placeholder={translate("select")}
                noOptionsMessage={() => translate("noAlternative")}
                options={categoryOptions}
                control={control}
            />
            <RHSelectInput
                formLabel={translate("products")}
                name="selection.productIds"
                placeholder={translate("select")}
                noOptionsMessage={() => translate("noAlternative")}
                isMulti
                options={productOptions}
                control={control}
            />
            <Flex justifyContent="flex-end">
                <FormErrorMessage marginRight="auto">{comboProductError}</FormErrorMessage>
                <Button
                    themeColor="gray"
                    ml={2}
                    onClick={addAllCategoryProducts}
                    disabled={disableCategorySelect || hasEveryProductSelected}
                >
                    {translate("chooseCategory")}
                </Button>
                <Button
                    themeColor="green"
                    ml={2}
                    onClick={addSelectedProducts}
                    disabled={disableProductSelect || hasEveryProductSelected}
                >
                    {translate("chooseProducts")}
                </Button>
            </Flex>
        </Box>
    );
};
