import { ComboDiscount, ComboCategory, ComboDiscountWithSelections } from "./comboDiscountFormTypes";
import { emptyArrayType, InitialDefaultValues, setInitialFormValues } from "Utils";
import { ComboDiscountType } from "Types";

/**
 * [FUNCTION] get combo discount initialvalues for form
 * @param companyId
 * @param comboDiscount passes in discount for edit or null/undefined for new
 * @returns initialValues (ComboDiscount)
 */
export const getComboDiscountInitialValues = (companyId: string, comboDiscount?: ComboDiscount) => {
    const currentDate = new Date();
    const { limit = 0, maxPerPurchase = 0 } = !!comboDiscount?.comboCategories?.length
        ? comboDiscount.comboCategories[0]
        : {};
    const defaultValues: InitialDefaultValues<Partial<ComboDiscountWithSelections>> = {
        companyId: companyId,
        name: "",
        description: "",
        shopIds: [],
        type: ComboDiscountType.STATIC_PRICE,
        comboCategories: emptyArrayType<ComboCategory>(),
        staticPrice: 0,
        limit: limit,
        maxPerPurchase: maxPerPurchase,
        startDate: currentDate,
        endDate: null,
        disabled: true,
        canCombine: true,
        selection: {
            categoryId: "",
            productIds: []
        }
    };

    const initialValues = setInitialFormValues<ComboDiscountWithSelections>(
        defaultValues as InitialDefaultValues<ComboDiscountWithSelections>,
        comboDiscount
    );

    return {
        initialValues
    };
};
