import { DiscountType, PosTypes } from "Constants";
import { EatingOption } from "Types";
import { emptyArrayType, InitialDefaultValues, setInitialFormValues } from "Utils";
import { Discount, DiscountMenuCategoryProductIds, DiscountWithMenus } from "./DiscountTypes";

/**
 * [FUNCTION] get discount initial values using type
 * Will set initial discount values or swap for current discount if this is an update
 * @param {DiscountType} type applied or fixed discount
 * @param {Discount} discount either undefined or has an already existing discount
 * @returns {Discount} Values in a discount type if new or updated
 */
export const getDiscountInitialValues = (type: DiscountType, discount?: DiscountWithMenus) => {
    const isAppliedDiscount = type === DiscountType.DISCOUNT;
    const defaultValues: InitialDefaultValues<Partial<DiscountWithMenus>> = {
        name: "",
        code: "",
        onlineCustomerMessage: "",
        amount: 0,
        rate: 0,
        startDate: new Date(),
        openPriceRate: false,
        preEnabled: false,
        combineWithOtherDiscounts: false,
        menuCategoryAndProductIds: emptyArrayType<DiscountMenuCategoryProductIds>(),
        maxRedeemed: 0,
        noRedeemed: 0,
        oneEmailLimit: false,
        shopIds: [],
        eatingOptions: emptyArrayType<EatingOption>(),
        disabled: false,
        canApplyOnEachProduct: false,
        canApplyOnWholeOrder: true,
        allowedEmails: [],
        availableOn: [],
        isACopy: false,
        ...(!isAppliedDiscount && { availableOn: [PosTypes.ONLINE], fixedDiscount: true, endDate: new Date() })
    };

    const initialValues = setInitialFormValues<Discount>(
        defaultValues as InitialDefaultValues<DiscountWithMenus>,
        discount
    );

    return {
        initialValues: initialValues
    };
};
