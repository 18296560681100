import { SelectOption } from "Molecules";
import { AuthenticatedUser, Shop } from "Types";
import { DiscountCompanyShop } from "./DiscountTypes";

/**
 * Function to get allowed shops to presented
 * @param {AuthenticatedUser} user current users
 * @param {boolean} copyDiscount is is a copy of a discount
 * @param {Shop[]} shops company shop data
 * @returns {SelectOption[]} options for select
 */
export const allowedCompanyShops = (
    user: AuthenticatedUser,
    copyDiscount: boolean,
    shops?: Shop[] | DiscountCompanyShop[]
): SelectOption[] => {
    if (!shops) {
        return [];
    }
    if (user.shopIds.length === 0) {
        return shops.map((shop: Shop | DiscountCompanyShop) => {
            return { value: shop.id, label: shop.name };
        });
    }

    if (copyDiscount) {
        return shops
            .filter(shop => user.shopIds.includes(shop.id))
            .map(shop => ({
                value: shop.id,
                label: shop.name,
                isDisabled: false,
                isFixed: false
            }));
    }

    return shops.map(shop => ({
        value: shop.id,
        label: shop.name,
        isDisabled: !user.shopIds.includes(shop.id),
        isFixed: !user.shopIds.includes(shop.id)
    }));
};
