import React from "react";
import { useFormContext, useWatch } from "react-hook-form";
import moment, { Moment } from "moment";

import { NewGrid as Grid, RHDatePickerInput } from "Atoms";
import { useLanguage, useQopla } from "Providers";
import { getMinimumEndDateFromStartDate } from "Utils";
import { DiscountWithMenus } from "../utils/DiscountTypes";

export const DiscountFormDates: React.FC<{ isDisabled?: boolean }> = ({ isDisabled = false }) => {
    const { translate } = useLanguage();
    const {
        selectedCompany: { countryLocale }
    } = useQopla();

    const { control, setValue, getValues } = useFormContext<DiscountWithMenus>();
    const startDate = useWatch({ control, name: "startDate" });
    const endDate = useWatch({ control, name: "endDate" });
    const datePickerLocaleCode = countryLocale.localeCode.toLowerCase();

    const minimumEndDate = getMinimumEndDateFromStartDate(startDate?.toString());
    const { fixedDiscount } = getValues();
    /**
     * [FUNCTION] if the start date is changed and the end date is before
     * it automatically changes it to the same as the start date
     * @param startDate
     */
    const changeEndDateIsBeforeStartDate = (startDate: Moment) => {
        if (endDate && moment(endDate).isBefore(startDate)) {
            setValue("endDate", startDate.toDate());
        }
    };

    return (
        <Grid templateColumns="1fr 1fr" gap="1em">
            <RHDatePickerInput
                isFullWidth
                formLabel={translate("startDate")}
                name="startDate"
                dateFormat="YYYY-MM-DD"
                locale={datePickerLocaleCode}
                minDate={moment()}
                autoComplete={"off"}
                control={control}
                transformBeforeOnChange={(moment: Moment) => {
                    changeEndDateIsBeforeStartDate(moment);
                    return moment.toDate();
                }}
                disabled={isDisabled}
                isMandatory
            />
            <RHDatePickerInput
                isFullWidth
                formLabel={translate("endDate")}
                name="endDate"
                dateFormat="YYYY-MM-DD"
                locale={datePickerLocaleCode}
                minDate={minimumEndDate}
                autoComplete={"off"}
                control={control}
                transformBeforeOnChange={(moment: Moment) => moment.toDate()}
                disabled={isDisabled}
                isMandatory={fixedDiscount}
            />
        </Grid>
    );
};
