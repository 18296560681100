import React, { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ComboDiscountWithSelections } from "../utils/comboDiscountFormTypes";
import { RHNumericInput, NewGrid as Grid } from "Atoms";
import { useLanguage } from "Providers";

export const ComboDiscountLimits: React.FC<{}> = ({}) => {
    const { translate } = useLanguage();
    const { control, setValue } = useFormContext<ComboDiscountWithSelections>();

    const comboCategories = useWatch({ control, name: "comboCategories" });
    const limit = useWatch({ control, name: "limit" });
    const maxPerPurchase = useWatch({ control, name: "maxPerPurchase" });

    /**
     * if new limit or maxPerPurchase is changed - it is also changed in the combo category / product list
     *  */
    useEffect(() => {
        if (!!comboCategories?.length) {
            const newLimitAndMax = comboCategories.map(cat => ({
                ...cat,
                limit: limit,
                maxPerPurchase: maxPerPurchase
            }));
            setValue("comboCategories", newLimitAndMax);
        }
    }, [limit, maxPerPurchase]);

    return (
        <>
            <RHNumericInput control={control} name="staticPrice" min={0} step="0.5" formLabel={translate("setPrice")} />
            <Grid templateColumns="1fr 1fr" gap="1em">
                <RHNumericInput control={control} name="limit" min={0} formLabel={translate("noOfProducts")} />
                <RHNumericInput
                    control={control}
                    name="maxPerPurchase"
                    formLabel={`${translate("maxPerOrder")} - (${translate("nullIsUnlimited")})`}
                />
            </Grid>
        </>
    );
};
