import React, { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";

import { IModalContext, useLanguage, useQopla } from "Providers";
import {
    Discount,
    DiscountAdminQuery,
    DiscountMenu,
    DiscountModalQuery,
    DiscountWithMenus,
    MenuDiscountPeriod,
    ShopMenu
} from "./utils/DiscountTypes";
import { useQoplaStore } from "Stores";
import { useMothershipMutation, useMothershipQuery } from "Hooks";
import { GET_ALCOHOL_PRODUCT_IDS } from "GraphQLQueries";
import { LoadingMessage, successNotification } from "TempUtils";
import { CountryLocaleId, EatingOption, Menu, MenuCategoryProducts } from "Types";
import { Modal, ModalBody, ModalCloseBtn, ModalHeader, SelectOption } from "Molecules";
import { DiscountType, modalNames } from "Constants";
import { Box, Button, Flex, Indicator, RHForm, Text } from "Atoms";
import { allowedCompanyShops } from "./utils/DiscountAdminFunctions";
import { getDiscountInitialValues } from "./utils/DiscountInitialValues";
import { getSchema } from "./utils/DiscountFormSchema";
import { formatFinancialNumbers, parseDateForJavaLocalDateTime } from "Utils";
import { UPSERT_DISCOUNT } from "GraphQLMutations";
import { DiscountForm } from "./components/DiscountForm";
import { DiscountLimits } from "./components/DiscountLimits";
import { DiscountMenuForm } from "./components/DiscountMenuForm";

type Props = {
    discountType: DiscountType;
    discount: Discount;
    menuDiscountPeriods?: MenuDiscountPeriod[];
    usedOnlineCodes: Set<string>;
    updateQuery: <T>(mapFn: (previousQueryResult: T) => T) => void;
    shopsAndMenus: DiscountAdminQuery.ShopsAndMenus;
    copyDiscount?: boolean;
};

export const DiscountAdminModal: React.FC<IModalContext<Props>> = ({ modalContent, closeModal }) => {
    const [showDiscountMenu, setShowDiscountMenu] = useState<Boolean>(false);
    const { translate } = useLanguage();
    const { authenticatedUser } = useQopla();
    const {
        companyLocale,
        selectedCompany: {
            id: companyId,
            settings: { sellingAlcohol = false }
        }
    } = useQoplaStore();

    const [createDiscount] = useMothershipMutation(UPSERT_DISCOUNT);

    const isSwedishCompany = (companyLocale?.baseName || CountryLocaleId.sv_SE) === CountryLocaleId.sv_SE;
    const isSwedishCompanyAndSellsAlcohol = isSwedishCompany && sellingAlcohol;


    const { data: alcoholProductIds, loading: alcoholProductsLoading } =
        useMothershipQuery<DiscountModalQuery.AlcoholProductIds>(GET_ALCOHOL_PRODUCT_IDS, {
            variables: { companyId: companyId },
            skip: !isSwedishCompanyAndSellsAlcohol
        });

    if (alcoholProductsLoading) return <LoadingMessage />;

    const {
        discountType,
        discount,
        menuDiscountPeriods,
        usedOnlineCodes: alreadyUsedOnlineCodes,
        updateQuery,
        shopsAndMenus,
        copyDiscount = false
    } = modalContent;

    const editDiscount = !!discount.id;
    const isFixedDiscount = discountType === DiscountType.FIXED_DISCOUNT;

    /** Fixed discount menu dates that are already taken
     * these are created on main fixed discount page so it is unaware
     * of the discount that will be selected
     * remove current menu selection if modifying
     */
    const datesMenusAreUnavailable =
        editDiscount && !copyDiscount
            ? menuDiscountPeriods?.filter(opt => opt.discountId !== discount.id)
            : menuDiscountPeriods;

    /** Passed into modal are all codes that are used
     * However this is here becuase if you delete the code by accident
     * and re-enter it would not be allowed
     */
    if (!!discount.code && !copyDiscount) {
        alreadyUsedOnlineCodes.delete(discount.code);
    }

    const eatingOptionsForDiscounts: SelectOption[] = [
        { value: EatingOption.EAT_HERE, label: translate(EatingOption.EAT_HERE) },
        { value: EatingOption.TAKE_AWAY, label: translate(EatingOption.TAKE_AWAY) },
        { value: EatingOption.HOME_DELIVERY, label: translate(EatingOption.HOME_DELIVERY) },
        { value: EatingOption.CATERING, label: translate(EatingOption.CATERING) }
    ];

    const allCompanyShops = shopsAndMenus?.getCompanyShopsForDiscounts;

    /** If authenticated user is allowed access to shops */
    const getAllowedShopList = allowedCompanyShops(authenticatedUser, copyDiscount, allCompanyShops);
    const shopIdsNotAbleToAccess = getAllowedShopList
        .filter((value: SelectOption) => value.isDisabled)
        .map(opt => opt.value);

    /** Gets all shop menus in object array menu ids with shop ids */
    const shopMenus: ShopMenu[] = allCompanyShops?.map(shop => ({ shopId: shop.id, menus: shop.menuIds })) ?? [];
    const allCompanyMenus = shopsAndMenus?.getCompanyMenusForDiscounts ?? [];

    /** Get shop ids used in the discount  **/
    const hasShopIds = discount.shopIds && getAllowedShopList;
    const shopsUsedInDiscount =
        hasShopIds && getAllowedShopList.filter((opt: SelectOption) => discount.shopIds.includes(opt.value));

    /** NOTE all isDisabled or isFixed add to options instead of values */
    const getEatingOptions =
        discount.eatingOptions &&
        eatingOptionsForDiscounts.filter((opt: SelectOption) =>
            discount.eatingOptions.includes(opt.value as EatingOption)
        );

    /** if it is a discount for update get menu ids from the menuCategoryAndProduct Object Array */
    const initialMenuIds = editDiscount
        ? discount?.menuCategoryAndProductIds?.map((menu: MenuCategoryProducts) => menu.menuId)
        : [];

    /** Get the extend property in form for current ids for selected menus
     *  (if an update) else empty array
     */
    const initialMenuSelection = editDiscount
        ? allCompanyMenus
              ?.filter((menu: DiscountMenu) => initialMenuIds?.includes(menu.id))
              ?.map((menu: DiscountMenu) => menu.id)
        : [];

    /** Shop ids for initial values  */
    const discountShopIds = shopsUsedInDiscount?.map(opt => opt.value) ?? [];

    /** Check for shops that have limited access
     * 1. canEditDiscount - if there is an id present it will be editable however if it is a copy then it is treated as a new discount
     * 2. hasDisabledShopsInDiscount - this checks the discounts shop ids against any that are not selectable
     * NOTE: this is telling inputs to disable as an admin could have access to one shop and still use the discount on that shop
     * 3. shouldDisableFormInputs - if discount has disabled ids and it is an edit discount (not new or a copy) it than is passed on to disable
     */
    const canEditDiscount = editDiscount && !copyDiscount;
    const hasDisabledShopsInDiscount = shopIdsNotAbleToAccess?.some(id => discountShopIds.includes(id)) ?? false;
    const shouldDisableFormInputs = hasDisabledShopsInDiscount && canEditDiscount;

    /** Re initialise the discount inc menu ids to send to initial values  */
    const reInitialiseDiscount: DiscountWithMenus = {
        ...discount,
        companyId: companyId,
        disabled: editDiscount ? !modalContent.discount.disabled : false,
        shopIds: discountShopIds,
        eatingOptions: getEatingOptions?.map(opt => opt.value as EatingOption) ?? [],
        selectedMenuIds: initialMenuSelection
    };

    const { initialValues } = getDiscountInitialValues(discountType, reInitialiseDiscount);

    let modalHeader: string = editDiscount
        ? `${translate("update")} "${discount.name}"`
        : translate("createANewDiscount");

    if (copyDiscount) {
        modalHeader = `${translate("copyDiscount")} - "${discount.name}"`;
    }

    const onSubmit = async (formValues: DiscountWithMenus) => {
        const { selectedMenuIds, isACopy, ...values } = formValues;

        const isPercentageDiscount = !!values.rate && values.rate > 0;
        const isCashValueDiscount = !!values.amount && values.amount > 0;
        const notAppliedOnProductOrWholeOrder =
            !isPercentageDiscount && !values.canApplyOnEachProduct && !values.canApplyOnWholeOrder;

        /**
         * canApplyOnEachProduct & canApplyOnWholeOrder
         * This only effects an amount discount displayed in cash register it will display 2 discounts
         * one for a product and one for the total. If it is a percentage discount nothing happens or amount online
         */
        const discount: Discount = {
            ...values,
            ...(isPercentageDiscount && { amount: null, rate: values.rate }),
            ...(isCashValueDiscount && { amount: values.amount, rate: null }),
            disabled: !values.disabled,
            emailOnlyDiscount: !!values.allowedEmails?.length,
            canApplyOnEachProduct: isPercentageDiscount || values.canApplyOnEachProduct,
            canApplyOnWholeOrder: isPercentageDiscount || values.canApplyOnWholeOrder,
            ...(notAppliedOnProductOrWholeOrder && { canApplyOnWholeOrder: true }),
            ...(!isFixedDiscount && {
                menuCategoryAndProductIds: selectedMenuIds?.length
                    ? values.menuCategoryAndProductIds.filter(
                          menu => menu.categoryIds.length > 0 || menu.productIds.length > 0
                      )
                    : []
            }),
            startDate: values.startDate ? parseDateForJavaLocalDateTime(moment(values.startDate).startOf("day")) : "",
            endDate: values.endDate ? parseDateForJavaLocalDateTime(moment(values.endDate).endOf("day")) : null
        };

        if (copyDiscount) {
            Reflect.deleteProperty(discount, "id");
        }

        try {
            const { data } = await createDiscount({ variables: { discount } });
            if (data) {
                const { upsertDiscount } = data;
                updateQuery((prev: any) => {
                    if (editDiscount && !copyDiscount) {
                        return {
                            getDiscountsOrFixedDiscounts: prev.getDiscountsOrFixedDiscounts.map(
                                (discount: Discount) => {
                                    return discount.id === upsertDiscount.id ? upsertDiscount : discount;
                                }
                            )
                        };
                    }
                    return {
                        getDiscountsOrFixedDiscounts: prev.getDiscountsOrFixedDiscounts.concat(upsertDiscount)
                    };
                });
                closeModal(modalNames.DISCOUNT_ADMIN_MODAL);
                successNotification(
                    `${values.name}`,
                    1400,
                    `${translate("discountType")}: ${
                        values.rate
                            ? `${values.rate} %`
                            : `${formatFinancialNumbers(Number(values.amount), companyLocale)}`
                    }`
                );
            }
        } catch (error) {
            console.log("Posting discount failed!!", error);
        }
    };

    /** Get validation schema for form  */
    const schema = getSchema(isFixedDiscount, alreadyUsedOnlineCodes, translate);

    /** If this a copy of the discount - resets some of the values  */
    const defaultValues = {
        ...initialValues,
        ...(copyDiscount && {
            name: "",
            code: "",
            startDate: new Date(),
            endDate: null,
            noRedeemed: 0,
            isACopy: true
        })
    };

    return (
        <Modal open={true} onClose={() => closeModal(modalNames.DISCOUNT_ADMIN_MODAL)} size="xl" isScrolling>
            <ModalCloseBtn onClick={() => closeModal(modalNames.DISCOUNT_ADMIN_MODAL)} />
            <ModalHeader>
                <Box ml={4}>{modalHeader}</Box>
            </ModalHeader>
            <ModalBody overflow="auto">
                <RHForm<DiscountWithMenus>
                    useFormProps={{ defaultValues: defaultValues, resolver: yupResolver(schema), mode: "onBlur" }}
                >
                    {({ handleSubmit, formState: { isValid, isDirty, isSubmitting }, watch }) => {
                        const saveButtonIsDisabled = !(isValid && isDirty);

                        const isDisabled = watch("disabled");

                        return (
                            <>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <Box position="absolute" top="27px" left="15px">
                                        <Indicator status={!isDisabled ? "inactive" : "positive"} />
                                    </Box>
                                    {!showDiscountMenu ? (
                                        <DiscountForm
                                            disableFormInputs={shouldDisableFormInputs}
                                            shopOptions={getAllowedShopList}
                                            eatingOptions={eatingOptionsForDiscounts}
                                        />
                                    ) : (
                                        <>
                                            {!isFixedDiscount && <DiscountLimits />}

                                            <DiscountMenuForm
                                                allCompanyMenus={allCompanyMenus}
                                                shopMenus={shopMenus}
                                                shopIdsForLimitedAccess={shopIdsNotAbleToAccess}
                                                menuDiscountPeriods={datesMenusAreUnavailable}
                                                disableFormInputs={shouldDisableFormInputs}
                                                alcoholProductIds={alcoholProductIds?.getAlcoholProductIds ?? []}
                                            />
                                        </>
                                    )}
                                    <Flex justify="space-between">
                                        <Button type="button" onClick={() => setShowDiscountMenu(prev => !prev)}>
                                            {!showDiscountMenu ? translate("advanced") : translate("back")}
                                        </Button>
                                        <Text ml={2} mt={2} marginRight="auto">
                                            {isFixedDiscount && `* (${translate("mustChooseAtLeastOneMenu")})`}
                                        </Text>
                                        <Button
                                            type="submit"
                                            themeColor="green"
                                            disabled={saveButtonIsDisabled}
                                            isLoading={isSubmitting}
                                        >
                                            {translate("save")}
                                        </Button>
                                    </Flex>
                                </form>
                            </>
                        );
                    }}
                </RHForm>
            </ModalBody>
        </Modal>
    );
};
